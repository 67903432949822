<template>
  <div>
    <ReportTable
      v-loading="tableLoading"
      :type="type"
      :title="title"
      :fields="fields"
      :items="items"
      :pageTotal="pageTotal"
      :currentPage="currentPage"
      :rows="rows"
      :path="path"
      :showHKSelect="false"
      :topExcel="true"
      :optionsCriteria="optionsCriteria"
      :optionsGenre="optionsGenre"
      :optionsLevel="optionsLevel"
      :optionsVersion="optionsVersion"
      :showExportText.sync="showExportText"
      :optionsOrigin="optionsOrigin"
      @handleSearch="handleSearch"
      @handleDateChange="handleDateChange"
      @handleCriteriaChange="handleCriteriaChange"
      @handleGenreChange="handleGenreChange"
      @handleLevelChange="handleLevelChange"
      @handleVersionChange="handleVersionChange"
      @handleOriginChange="handleOriginChange"
      @exportToExcel="exportToExcelFile()"
    />
    <el-dialog
      destroy-on-close
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="Excel Export"
      :visible.sync="exportDialog"
      width="30%"
    >
      <span>數據下載中，請勿離開...</span>
    </el-dialog>
  </div>
</template>
<script>
import ReportTable from "./components/reportTable.vue"
import { getHighest, getHighestExport } from "@/api/specialRecord"
import { getGenreApi } from "@/api/film-material-management/genre"
import { getManageProperties } from "@/api/properties"
export default {
  name: "ReportVersion",
  components: {
    ReportTable,
  },
  data() {
    return {
      exportDialog: false,
      showExportText: false,
      tableLoading: false,
      type: "toprank",
      title: "22.5 最高排名",
      currentPage: 1,
      selectDropdown: true,
      path: "special-film-record",
      fields: [
        {
          key: "name",
          label: "電影名稱",
          sortable: true,
        },
        {
          key: "publisher",
          label: "發行商",
          sortable: true,
        },
        {
          key: "film",
          label: "片種",
          sortable: true,
        },
        {
          key: "origin",
          label: "產地",
          sortable: true,
        },
        {
          key: "level",
          label: "級別",
          sortable: true,
        },
        {
          key: "date_on",
          label: "上映日期",
          sortable: true,
        },
        {
          key: "date_off",
          label: "落畫日期",
          sortable: true,
        },
        {
          key: "first_day_bo",
          label: "首日票房",
          sortable: true,
        },
        {
          key: "visitors",
          label: "首日人次",
          sortable: true,
        },
        {
          key: "bo_first",
          label: "開畫首週票房",
          sortable: true,
        },
        {
          key: "first_week_visitors",
          label: "開畫首週人次",
          sortable: true,
        },
        {
          key: "single_day_highest_date",
          label: "最高單日日期",
          sortable: true,
        },
        {
          key: "single_day_highest",
          label: "最高單日票房",
          sortable: true,
        },
        {
          key: "single_day_highest_visitors",
          label: "最高單日人次",
          sortable: true,
        },
        {
          key: "bo_cummulative",
          label: "累積票房",
          sortable: true,
        },
        {
          key: "visit_cummulative",
          label: "累積人次",
          sortable: true,
        },
        {
          key: "highest_date_on_bo",
          label: "最高優先場票房",
          sortable: true,
        },
        {
          key: "highest_date_on_ad",
          label: "最高優先場人次",
          sortable: true,
        },
      ],
      items: [],
      optionsCriteria: [
        { text: "首日票房", value: "first_day_bo", key: "first_day_bo" },
        { text: "首週票房", value: "week_bo", key: "bo_first" },
        {
          text: "最高單日票房",
          value: "highest_day_bo",
          key: "single_day_highest",
        },
        { text: "累積票房", value: "total_bo", key: "bo_cummulative" },
        { text: "首日人次", value: "first_day_ad", key: "visitors" },
        { text: "首週人次", value: "week_ad", key: "first_week_visitors" },
        {
          text: "最高單日人次",
          value: "highest_day_ad",
          key: "single_day_highest_visitors",
        },
        { text: "累積人次", value: "total_ad", key: "visit_cummulative" },
        {
          text: "最高優先場票房",
          value: "highest_date_on_bo",
          key: "highest_date_on_bo",
        },
        {
          text: "最高優先場人次",
          value: "highest_date_on_ad",
          key: "highest_date_on_ad",
        },
      ],
      selectedCriteria: "&sort_by=total_bo",
      optionsGenre: [
        { text: "動作", value: "動作" },
        { text: "冒險", value: "冒險" },
        { text: "動畫", value: "動畫" },
        { text: "喜劇", value: "喜劇" },
        { text: "犯罪", value: "犯罪" },
        { text: "災難", value: "災難" },
        { text: "紀錄", value: "紀錄" },
        { text: "劇情", value: "劇情" },
        { text: "歷史", value: "歷史" },
        { text: "奇幻", value: "奇幻" },
      ],
      selectedGenre: "",
      optionsLevel: [],
      selectedLevel: "",
      optionsVersion: [],
      selectedVersion: "",
      optionsOrigin: [],
      selectedOrigin: "",
      pageTotal: 0,
      perPage: 5,
      rows: 1000,
      search: "",
      startDate: "",
      endDate: "",
    }
  },
  methods: {
    exportToExcelFile() {
      this.tableLoading = true
      const data = `date_start=${this.startDate}&date_end=${this.endDate}${this.selectedCriteria}${this.selectedGenre}${this.selectedLevel}${this.selectedVersion}${this.selectedOrigin}&export=1`
      getHighest(data)
        .then((res) => {
          this.getHighestExport(res.data.backlog)
        })
        .catch((error) => {
          this.tableLoading = false
        })
    },
    getHighestExport(backlog) {
      if (window.innerWidth < 1000) {
        this.showExportText = true
      } else {
        this.exportDialog = true
      }
      getHighestExport(backlog)
        .then((res) => {
          if (!res) {
            setTimeout(() => {
              this.getHighestExport(backlog)
            }, 5000)
          } else {
            var binaryData = []
            binaryData.push(res)
            let eLink = document.createElement("a")
            eLink.download =
              `Report Date/Time: ${this.$moment().format("YYYY-MM-DD/HH:mm")}` +
              ".xlsx"
            eLink.style.display = "none"

            let blob = new Blob(binaryData, {
              type: "application/vnd.ms-excel",
            })
            eLink.href = URL.createObjectURL(blob)
            document.body.appendChild(eLink)
            eLink.click()
            document.body.removeChild(eLink)
            this.exportDialog = false
            this.showExportText = false
            this.tableLoading = false
          }
        })
        .catch((error) => {
          console.log(error, "error")
          this.showExportText = false
          this.exportDialog = false
          this.tableLoading = false
        })
    },
    exportToExcel() {
      console.log(this.items)
      //tabel list
      const header = []
      this.fields.map((el) => {
        header.push(el.label)
      })
      const dataList = []
      this.items.map((item) => {
        const distributors = []
        // if (item.distributor && item.distributor.length > 0) {
        //   item.distributor.map((distr) => {
        //     distributors.push(
        //       `${distr.distributor_name_zh_hk} ${distr.distributor_name_en}`
        //     );
        //   });
        // }
        // const company = [];
        // item.company.map((com) => {
        //   company.push(
        //     `${com.production_name_en}-${com.production_name_zh_hk}`
        //   );
        //  });
        const list = []
        list.push(
          item.name,
          item.publisher,
          item.film,
          item.origin,
          item.level,
          new Date(item.date_on),
          this.$moment(
            this.dateOffFormat(item.date_off, item.date_release)
          ).isValid()
            ? new Date(item.date_off)
            : this.dateOffFormat(item.date_off, item.date_release),
          Number(item.first_day_bo),
          Number(item.visitors),
          Number(item.bo_first),
          Number(item.first_week_visitors),
          new Date(item.single_day_highest_date),
          Number(item.single_day_highest),
          Number(item.single_day_highest_visitors),
          Number(item.bo_cummulative),
          Number(item.visit_cummulative),
          Number(item.highest_date_on_bo),
          Number(item.highest_date_on_ad)
        )
        dataList.push(list)
      })
      dataList.unshift(header)
      dataList.push([
        `Report Date/Time: ${this.$moment().format("YYYY-MM-DD/HH:mm")}`,
      ])
      const head1 = header.slice(7, 11)
      const head2 = header.slice(12, 18)
      const numFmt = head1.concat(head2)
      const numList = []
      numFmt.map((key) => {
        numList.push({
          title: this.getAlphabetLetter(header.indexOf(key)),
          index: 2,
        })
      })
      const excelInfo = {
        type: "22.5",
        merge: true,
        setDateValue: false,
        data: this.items,
        dateCol: [],
        fileName: `Top ranking B.O. Report (${this.startDate} to ${this.endDate}).xlsx`,
        header,
        excelData: dataList,
        numFmt: true,
        numFmtList: numList,
        hasTotal: false,
      }
      this.excelFun(excelInfo, "noapi")
    },
    getManageProperties(type) {
      console.log()
      getManageProperties(type)
        .then((res) => {
          console.log(res)
          const arr = []
          if (type === "film_rating") {
            res.data.map((el) => {
              arr.push({ text: el.name, value: el.name })
            })
            this.optionsLevel = arr
            console.log(this.selectedLevel)
          } else if (type === "film_visual_effect") {
            res.data.map((el) => {
              arr.push({ text: el.name, value: el.name })
            })
            this.optionsVersion = arr
          } else {
            res.data.map((el) => {
              if (!el.name.includes("/")) {
                const name =
                  type === "film_country"
                    ? `${el.name_zh_hk} (${el.name_en})`
                    : el.name
                arr.push({ text: name, value: el.name })
              }
            })
            this.optionsOrigin = arr
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleDateChange(from, to) {
      this.startDate = from
      this.endDate = to
    },
    handleCriteriaChange(val) {
      this.selectedCriteria = val
    },
    handleGenreChange(val) {
      this.selectedGenre = val
    },
    handleLevelChange(val) {
      this.selectedLevel = val
    },
    handleVersionChange(val) {
      this.selectedVersion = val
    },
    handleOriginChange(val) {
      this.selectedOrigin = val
    },
    handleLocationChange(val) {
      this.location = val
    },
    handleSearch(val) {
      // const query = {
      //   startDate: this.startDate,
      //   endDate: this.endDate,
      //   Criteria: this.selectedCriteria,
      //   Level: this.selectedLevel,
      //   Version: this.selectedVersion,
      //   Origin: this.selectedOrigin
      // }
      // this.currentPage = 1
      // this.$router.push({ name: 'top-ranking', query })
      this.getReport()
    },
    getReport() {
      this.tableLoading = true
      const data = `date_start=${this.startDate}&date_end=${this.endDate}${this.selectedCriteria}${this.selectedGenre}${this.selectedLevel}${this.selectedVersion}${this.selectedOrigin}`
      console.log(data)
      getHighest(data)
        .then((res) => {
          console.log(res, " getHighest")
          const arr = []
          this.pageTotal = res.data.length
          this.items = arr
          res.data.forEach((el) => {
            let film_cat = []
            if (el.film_cat && el.film_cat.length > 0) {
              // const film_cat = [];
              el.film_cat.map((item) => {
                film_cat.push(item.filmcat_name_zh_hk)
              })
            }
            const obj = new Object()
            obj.name = `${el.film_master.name_chinese} (${el.film_master.name_english})`
            obj.publisher =
              el.distributor && el.distributor.length > 0
                ? `${el.distributor[0].distributor_name_zh_hk} (${el.distributor[0].distributor_name_en})`
                : "-"
            obj.film = film_cat.length > 0 ? film_cat.join(",") : "-"
            obj.origin = el.film_master.country
            obj.level = el.film_master.film[0].rating

            obj.date_on = el.film_master.film[0].date_release
            obj.date_off = el.film_master.film[0].date_off
            obj.date_release = el.film_master.film[0].date_release
            obj.first_day_bo = el.first_day_bo
            obj.visitors = el.first_day_ad
            obj.bo_first = el.first_week_bo
            obj.first_week_visitors = el.first_week_ad
            obj.single_day_highest_date = el.highest_on_date
            obj.single_day_highest = el.highest_day_bo
            obj.single_day_highest_visitors = el.highest_day_ad
            obj.bo_cummulative = el.total_bo
            obj.visit_cummulative = el.total_ad
            obj.highest_date_on_bo = el.highest_date_on_bo
            obj.highest_date_on_ad = el.highest_date_on_ad
            arr.push(obj)
          })
          this.items = arr
          this.tableLoading = false
        })
        .catch((error) => {
          this.tableLoading = false
        })
    },
    getGenreList() {
      const data = {
        page: 1,
        count: 1000,
      }
      getGenreApi(data)
        .then((res) => {
          this.optionsGenre = res.data
          const arr = []
          this.optionsGenre.forEach((el) => {
            const obj = new Object()
            obj.value = el.id
            obj.text = `${el.filmcat_name_zh_hk} (${el.filmcat_name_en})`
            arr.push(obj)
          })
          this.optionsGenre = arr
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  mounted() {
    if (!this.$route.query.startDate) {
      const oneMonthAgoDate = new Date()
      oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1)
      this.startDate = oneMonthAgoDate.toISOString().slice(0, 10)
    }
    if (!this.$route.query.endDate) {
      this.endDate = this.$moment().format("YYYY-MM-DD")
    }
    const manlist = ["film_rating", "film_visual_effect", "film_country"]
    manlist.map((el) => {
      this.getManageProperties(el)
    })
    this.getReport()
    this.getGenreList()
  },
}
</script>
